import React, {ChangeEvent, useEffect, useState} from "react";
import ProfitCenterPicker from "./components/ProfitCenterPicker";
import Loading from "./components/Loading";
import {PriceRow} from "./PriceRow";
import {useUser} from "./UserContext";
import {PriceItem, ProfitCenter, RoleNames} from "./types";
import {useNavigate, useParams} from "react-router-dom";
import {parseRangeHeader} from "workbox-range-requests/utils/parseRangeHeader";
import {useFormatters} from "./components/formatters";
import {useSettings} from "./SettingsContext";

interface State{
  profitCenterId: number;
  description: string;
  priceListName: string;
  items: PriceItem[];
  categories?: string[];
}
interface Params {
  shopId?: string
}
const Prices = () => {
  const navigate = useNavigate();
  const params = useParams<Params>();
  const {settings} = useSettings();
  const formatters = useFormatters(settings);
  
  const [editingIdx, setEditingIdx] = useState(-1);
  const {user}  = useUser();
  const [filter, setFilter] = useState("");
  const debug = false;
  const [categoryFilter, setCategoryFilter] = useState("");
  const [includedFilter, setIncludedFilter] = useState(false);
  const [filteredItems, setFilteredItems] = useState<PriceItem[]>([]);

  const [profitCenters, setProfitCenters] = useState<ProfitCenter[]>([]);
  const [profitCenterId, setProfitCenterId] = useState(params.shopId ? parseInt(params.shopId) : -1);
  
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string|null>(null);
  const [state, setState] = useState<State>({
    profitCenterId: -1,
    description: "",
    priceListName: "",
    items: [],
  });
  const [modifiedItems, setModifiedItems] = useState<PriceItem[]>([]);

  useEffect(() => {
    fetch('account/explicit-profitcenter-access')
      .then(r => r.json())
      .then(data => {
        setProfitCenters(data);
        // if(profitCenterId === -1 && data.length > 0){
        //   setProfitCenterId(data[0].code);
        // }
      });
  }, []);

  
  useEffect(() => {
    if(profitCenterId === -1){
      return;
    }
    setLoading(true);
    setFilteredItems([]);
    setState({categories: [], description: "", items: [], priceListName: "", profitCenterId: -1});
    fetch(`/api/pricelists/${profitCenterId}`)
      .then(response => {
        if(!response.ok){
          throw new Error(`StatusCode: ${response.status}`, {cause:response});
        }
        return response.json()
      })
      .then(data => {
        if(data && data.items.length > 0){
          const arr = data.items.map(x => x.category);
          const unique = arr.filter((v, i, a) => a.indexOf(v) === i).sort();
  
          setState({
            ...data,
            categories: unique,
          });
          
          setFilteredItems(data.items);
        }
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if(e.cause.status === 403){
          setError('Du har inte behörighet att se och redigera prislistorna för detta kostnadssställe. Be din administratör ge dig direkt access till detta kostnadsställe.');  
        }
    });
  }, [profitCenterId]);
  
  
  const saveItem = async (priceItem:PriceItem) => {
    const data = await fetch('/api/prices', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ean: priceItem.ean, price: priceItem.newPrice, profitCenterId: profitCenterId})
    }).then(r => r.json());

    setState({ ...state, items: state.items.map(i => i.ean === priceItem.ean ? data : i) });
  }
  
  const saveModifiedItems = async () => {
    setSaving(true);
    for (const item of modifiedItems) {
      await saveItem(item);
    }
    setModifiedItems([]);
    setSaving(false);
  };
  const onItemChanged = (item:PriceItem, newPrice:number) => {
    let priceChange = modifiedItems.find(i=>i.ean === item.ean) as PriceItem;
    if(newPrice === ''){
      if(priceChange){
        const newValue = modifiedItems.filter(i=>i.ean !== item.ean);
        setModifiedItems(newValue);
        setModifiedPricesInLS(profitCenterId, newValue);
      }
      return;  
    }
    
    
    if(priceChange){
      //there is already a priceChange for this item waiting to be saved, so we update that instead of adding a new one.
      const changes = modifiedItems.filter(i=>i.ean !== item.ean);
      const newValue = [...changes, {...priceChange, newPrice: newPrice}];
      setModifiedItems(newValue);
      setModifiedPricesInLS(profitCenterId, newValue);
    } else {
      const newValue = [...modifiedItems, {...item, newPrice: newPrice}];
      
      setModifiedItems(newValue);
      setModifiedPricesInLS(profitCenterId, newValue);
    }
  };
  
  const setModifiedPricesInLS = (shopId:number, items:PriceItem[]) => {
    
    const modifiedPricesStr = localStorage.getItem("modifiedPrices");
    const modifiedPrices = JSON.parse(modifiedPricesStr || "{}");    
    modifiedPrices[shopId] = items.map(i=>({ean:i.ean, newPrice:i.newPrice}));
    
    localStorage.setItem("modifiedPrices", JSON.stringify(modifiedPrices));
  };

  let excludedChanged = (ean:string, excluded:boolean) => {
    const verb = excluded ? 'POST' : 'DELETE';
    fetch(`api/products/${profitCenterId}/${ean}/exclude`, {
      method: verb
    }).then(response=>{
      const item = state.items.find(i=>i.ean === ean);
      if(item){
        item.isExcluded = excluded;
        setState({
          ...state,
          items: state.items.map(i=>i.ean === ean ? item : i)
        });
      }else {
        console.error('Could not find item', ean);
      }
    });
    //TODO error management
  };
  const findNextEditableRow = (currentItem:PriceItem) => {
    const idx = filteredItems.indexOf(currentItem);
    let nextIdx = idx + 1;
    while (nextIdx < filteredItems.length && filteredItems[nextIdx].isExcluded) {
      nextIdx++;
    }
    return nextIdx;
  }
  
  const findPrevEditableRow = (currentItem:PriceItem) => {
    const idx = filteredItems.indexOf(currentItem);
    let prevIdx = idx - 1;
    while (prevIdx >= 0 && filteredItems[prevIdx].isExcluded) {
      prevIdx--;
    }
    return prevIdx;
  }
  
  const onNextRow = (currentItem:PriceItem) => {
    const idx = filteredItems.indexOf(currentItem);
    if (idx < filteredItems.length - 1) {
      setEditingIdx(findNextEditableRow(currentItem));
    }
  }
  const onPrevRow = (currentItem:PriceItem) => {
    const idx = filteredItems.indexOf(currentItem);
    if (idx > 0) {
      setEditingIdx(findPrevEditableRow(currentItem));
    }
  };

  const filterItems = (text:string, category:string, includedOnly:boolean)=>{
    console.log('filtering:',text, category, includedOnly);
    
    let filtered = state.items;
    
    if(category){
      console.log('category filter applied:', category)
     filtered = filtered.filter(item=>item.category.toLowerCase() === category.toLowerCase());
    }
    
    if(includedOnly){
      console.log('included filter applied:', includedOnly)
      filtered = filtered.filter(item => !item.isExcluded);
    }

    console.log('beforeFilter',filtered.length);
    if(text != null && text.length > 0) {
      console.log('text filter applied:', text)
      filtered = filtered.filter(item => 
        item.description.toLowerCase().indexOf(text.toLowerCase()) > -1
        || item.children.filter(child=>child.code?.toLowerCase().indexOf(text.toLowerCase()) > -1).length > 0
      );
    }

    console.log('afterFilter',filtered.length);
    //console.log('filtered',filtered.map(x=>x.description));
    
    setFilteredItems(filtered);
    setEditingIdx(-1);
  }
  
  const filterChanged = (e:ChangeEvent<HTMLInputElement>) => {
    const filter = e.target.value;
    setFilter(filter);
    filterItems(filter, categoryFilter, includedFilter);
  };
  
  const includedFilterChanged = (e:ChangeEvent<HTMLInputElement>) =>{
    setIncludedFilter(e.target.checked);
    filterItems(filter, categoryFilter, e.target.checked);
  }
const categoryFilterChanged = (e:ChangeEvent<HTMLSelectElement>)=>{
    setCategoryFilter(e.target.value);
    filterItems(filter, e.target.value, includedFilter);
  }
  
  const editClicked = (item:PriceItem) => {
    const idx = filteredItems.indexOf(item);
    setEditingIdx(idx);
  };
  const profitCenterChanged = (p:ProfitCenter) => {
    setProfitCenterId(p.code);
    navigate(`/prices/${p.code}`);
  }
  
  const getSelectedProfitCenter = () => {
    if(profitCenterId === -1)
      return null;
    return profitCenters.find(p => p.code === profitCenterId) 
  }
  
  const currentProfitCenterName = ()=>{
    return getSelectedProfitCenter()?.name
  }
  
  if(loading)
    return (<Loading visible={true}/>);

  if(profitCenterId == -1){
    return (
      <div className={"edit-price"}>
        <h1>Välj ett kostnadsställe</h1>
        <ProfitCenterPicker
          profitCenters={profitCenters} 
          onProfitCenterSelected={profitCenterChanged} 
          selectedProfitCenter={getSelectedProfitCenter()}
        />
        <div className={"alert alert-info"}>
          <p>Välj det kostnadsställe du vill redigera priser för.</p>
        </div>
      </div>)
  }
  const isAdministrator = user && user.roles.includes(RoleNames.Administrator);
  
  return (
    <div className={"edit-price"}>
      <div className="filters filter-container">
        <ProfitCenterPicker 
          profitCenters={profitCenters} 
          onProfitCenterSelected={profitCenterChanged} 
          selectedProfitCenter={getSelectedProfitCenter()}
        />
        <h1>Priser - {currentProfitCenterName()} ({profitCenterId})</h1>

        {!state.items.length && (
          <>
            
            {error && (
              <div className={"alert alert-danger "}>
                <p>{error}</p>
              </div>
            )}
            {!error && (
              <div className={"alert missing-price-list"}>
                <p>
                  Ingen specifik prislista kunde hittas för kostnadsstället {currentProfitCenterName()}.
                  Det betyder att detta kostnadsställe använder standardpriserna, alternativt så har prislistan inte konfigurerats på kostnadsställets standard-terminal/kassa.
                </p>
            </div>
            )}
          </>
        )}

        <div className={"filter"}>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>Sök</label>
            <input className={"form-control"} type={"search"} value={filter} onChange={filterChanged}/>
          </div>

          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>Kategorier</label>
            <select className={"form-select"} value={categoryFilter} onChange={categoryFilterChanged}>
              <option value={""}>Alla</option>
              {state.categories?.map(c => (
                <option key={c}>{c}</option>
              ))}
            </select>
          </div>
          

          <div className={"checkbox-wrapper"}>
            <input id={"chkIncludedOnly"} type={"checkbox"} checked={includedFilter} name={"chkIncludedOnly"}
                   onChange={includedFilterChanged} tabIndex={-1}/>
            <label className="toggle" htmlFor={"chkIncludedOnly"}>
                <span className="toggler">
                  <svg width="10px" height="10px" viewBox="0 0 10 10">
                    <path
                      d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                  </svg>
                </span>

            </label>
            <label htmlFor={"chkIncludedOnly"}>
              <span>Visa endast valda artiklar.</span>
            </label>
          </div>
          
          <div>
          </div>
        </div>
      </div>

      {modifiedItems && modifiedItems.length > 0 && (
        <div className={"alert alert-info save-banner"}>
          <p>Du har ändrat priser för <b>{modifiedItems.length}</b> artiklar. Klicka på "Spara" för att spara ändringarna.</p>
          <p>Resultatet av dina ändringar kommer att slå igenom efter nästa inläsning.</p>
          {debug &&(
            <ol>
              {modifiedItems.map(i => (
                <li key={i.ean}>{i.description} - {formatters.currency.format(i.newPrice)}</li>
              ))}
            </ol>
          )}
          
          <button disabled={saving} onClick={saveModifiedItems} className={"btn btn-save"}>Spara</button>
          <Loading visible={saving} />
        </div>
      )}
      
      <ul className={"price-list"}>
        <li className="price-row head-row">
          <div className={'excluded-cb'}></div>
          <div className={"description"}>
            <span className={"label"}>Namn</span>
          </div>
          <div className={"category"}>
            <span className={"label"}>Kategori</span>
          </div>
          <div className={"type"}>
            <span className={"label"}>Typ</span>
          </div>
          <div className={"description2"}>
            <span className={"label"}>Beskrivning</span>
          </div>
          <div className={"codes"}>
            <span className={"label"}>Artikelkoder</span>
          </div>
          <div className={"ean-code"}>
            <span className={"label"}>EAN</span>
          </div>
          <div className={"default-price"}>
            <span className={"label"}>Grundpris</span>
          </div>
          <div className={"new-price"} onClick={editClicked}>
            <span className={"label"}>Lokalt pris</span>
          </div>
          <div className={"new-price"}>
            <span className={"label"}>Nytt pris</span>
          </div>
          <div className={'edit-btn'}></div>
        </li>
        {filteredItems.map((item, idx) => (
          <PriceRow key={profitCenterId + '-' + item.ean}
                    modified={modifiedItems.find(i => i.ean === item.ean)}
                    hasEdits={modifiedItems.some(i => i.ean === item.ean)}
                    item={item} editing={idx === editingIdx}
                    onChange={onItemChanged}
                    onNextRow={onNextRow}
                    onPrevRow={onPrevRow}
                    onEditClicked={editClicked}
                    onExcludedChanged={excludedChanged}
                    onCancelEdit={() => setEditingIdx(-1)}
          />
        ))}
      </ul>
      {isAdministrator && !error && (
      <div className="price-footer">
        <div className={'btn-group'} role={"group"}>
          <a download href={`api/export/prices/${profitCenterId}`} className={"btn btn-primary"}>Exportera prislista</a>
          <a download href={`api/export/excludes/${profitCenterId}`} className={"btn btn-primary"}>Exportera släcklista</a>
        </div>
        
        {/* <p>
          Ovanstående knappar kommer inte vara synliga för franchisetagare, utan visas bara för utveckling och test-syfte.
          Resultatet av prisändringarna kommer att synas i Winpos efter en synkronisering efter manuell import av winpos-personal.
        </p> */}
      </div>
      )}
    </div>
    
  )
};

export default Prices;